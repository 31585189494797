<template>
  <div class="Empresa">
    <!-- Banner !-->
    <div
      class="flex items-center w-full h-38em md:h-45em bg-layout_green bg-cover bg-no-repeat bg-center pt-4 md:pt-8 pb-4 md:pb-8 relative"
    >
      <div class="mx-5 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-2">
          <div class="col-span-12 lg:col-span-7">
            <span class="text-white font-Montserrat text-xl">QUEM SOMOS</span>
            <h1
              class="text-white font-Montserrat text-4xl md:text-6xl mt-3"
            >
              Pedreiras Boscardin é tradição e qualidade
            </h1>
            <img class="h-28 md:h-32 absolute bottom-0 md:bottom-52 right-0" src="@/assets/experiencia.png" />
          </div>
        </div>
      </div>
    </div>
    <!-- Info !-->
    <div id="info" class="py-12 md:py-28 mx-5 md:mx-12 2xl:mx-96">
      <div class="grid grid-cols-12 gap-6">
        <div class="col-span-12 md:col-span-5">
          <img src="@/assets/boscardin_pedreira.png" alt="boscardin pedreira" />
        </div>
        <div class="col-span-12 md:col-span-7 md:px-12 md:pt-20">
            <h2
              class="text-gray-900 font-Montserrat font-semibold text-2xl md:text-4xl"
            >
              Conheça mais sobre nossa empresa
            </h2>
            <p class="text-gray-600 font-Montserrat text-base mt-5">
              A empresa Pedreiras Boscardin atua a 60 anos na produção de britas/agregados. É hoje uma das empresas mais conceituadas do segmento. Possui unidades de produção em Irati, Ponta Grossa e Piraquara. Têm expressiva participação no mercado de agregados, utilizando como matéria prima basalto, granito e diabásio na construção de casas, edifícios, shoppings, e principalmente em rodovias e na malha viária das cidades, dentre outros.
            </p>
        </div>
      </div>
    </div>
    <!-- Atuação !-->
    <div id="atuacao" class="py-12 md:py-28 bg-terciary">
        <div class="mx-5 md:mx-12 2xl:mx-96">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 md:col-span-8 md:px-12 md:pt-20">
                    <h2
                    class="text-gray-900 font-Montserrat font-semibold text-2xl md:text-4xl"
                    >
                        Atuamos na extração da rocha e beneficiamento de materiais
                    </h2>
                    <p class="text-gray-600 font-Montserrat text-base mt-5">
                        Fornecemos pedras diretas da mineração, garantindo assim, a melhor qualidade, além da confiança de quantitativos na hora da entrega. Materiais que trabalhamos:
                    </p>
                    <div class="grid grid-cols-12 gap-6 mt-4">
                        <div class="col-span-12 md:col-span-5">
                            <ul class="list-disc ml-4">
                                <li class="text-gray-600 font-Montserrat text-base mt-2">Pedra bruta detonada</li>
                                <li class="text-gray-600 font-Montserrat text-base mt-2">Brita 1, 2 e 3</li>
                                <li class="text-gray-600 font-Montserrat text-base mt-2">Pó de pedra</li>
                                <li class="text-gray-600 font-Montserrat text-base mt-2">Granilha </li>
                            </ul>
                        </div>
                        <div class="col-span-12 md:col-span-5">
                            <ul class="list-disc ml-4">
                                <li class="text-gray-600 font-Montserrat text-base mt-2">Matacão do pulmão</li>
                                <li class="text-gray-600 font-Montserrat text-base mt-2">Pedrisco</li>
                                <li class="text-gray-600 font-Montserrat text-base mt-2">Brita graduada</li>
                                <li class="text-gray-600 font-Montserrat text-base mt-2">Rachão</li>
                            </ul>
                        </div>
                    </div>
                </div>
                 <div class="col-span-12 md:col-span-4">
                    <img src="@/assets/boscardin_atuacao.png" alt="boscardin pedreira" />
                </div>
            </div>
        </div>
    </div>
    <!-- Diferenciais !-->
    <div id="diferenciais" class="py-12 md:py-28 bg-terciary">
        <div class="mx-5 md:mx-12 2xl:mx-96">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 md:col-span-5">
                    <img src="@/assets/bosrcardin_diferenciais.png" alt="boscardin diferenciais" />
                </div>
                <div class="col-span-12 md:col-span-7 md:px-12 md:pt-20">
                    <h2
                    class="text-gray-900 font-Montserrat font-semibold text-2xl md:text-4xl"
                    >
                        Nossos diferenciais
                    </h2>
                    <p class="text-gray-600 font-Montserrat text-base mt-5">
                        A empresa possui frota própria de transporte e realiza investimentos constantes na melhoria da qualidade de seus serviços.
                    </p>
                    <p class="text-gray-600 font-Montserrat text-base mt-5">
                       Hoje contamos com equipamentos de britagem  que  proporcionam maior produção,  possibilitando que o material permaneça dentro dos padrões de qualidade e dentro das normas técnicas oficiais de operação.
                    </p>
                    <p class="text-gray-600 font-Montserrat text-base mt-5">
                       O grande diferencial das Pedreiras Boscardin é oferecer a mais alta qualidade em serviços e a satisfação de seus clientes, tornando-a muito competitiva perante os concorrentes.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- historia !-->
    <div id="historia" class="py-12 md:py-28 bg-terciary">
        <div class="mx-5 md:mx-12 2xl:mx-96">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 md:col-span-12">
                    <h2
                    class="text-gray-900 font-Montserrat font-semibold text-2xl md:text-4xl"
                    >
                        Uma história de Sucesso
                    </h2>
                    <p class="text-gray-600 font-Montserrat text-base mt-5">
                        Pedreiras Boscardin, empresa familiar liderada por Agostinho Boscardin, iniciou suas atividades muito discretamente nos anos de 1950 em Riozinho – Irati – Paraná, com um empréstimo solicitado ao interventor Manoel Ribas. Na propriedade, Agostinho descobriu uma pedreira e a partir dali passou a dedicar-se de corpo e alma à extração de pedras, inicialmente fornecendo para alicerces de casas, escolas, hospitais entre outros.
                    </p>
                    <p class="text-gray-600 font-Montserrat text-base">
                       Naquela época, as pedras eram quebradas por marretas e martelos, não existiam máquinas (britadores) e eram transportadas por carroças. A medida de venda era “lata” e não por metro cúbico ou tonelada como atualmente.
                    </p>
                    <p class="text-gray-600 font-Montserrat text-base mt-5">
                       A partir de 1956, com o primeiro contrato assinado com a Rede Ferroviária Federal, a produção precisou aumentar, e já com 10 “operário britadores”, cada um conseguia britar (quebrar) cerca de 1 metro cúbico de pedra por dia, portanto, tínhamos uma produção diária excepcional de 10 metros cúbicos e, uma vez ao mês, um trem levava o material para Ponta Grossa onde era utilizado entre os dormentes da estrada de ferro. Em 1957, foi adquirido o primeiro britador, uma máquina movida a óleo diesel com capacidade de britar até 30 metros cúbicos de pedra diariamente.
                    </p>
                    <p class="text-gray-600 font-Montserrat text-base mt-5">
                       No ano de 1965, comprou uma área às margens do rio Passaúna, município de Curitiba, e iniciou outra pedreira, permanecendo ali até transferir suas atividades em janeiro de 1976 para o município de Piraquara, Região Metropolitana de Curitiba, vislumbrando uma nova oportunidade. Em 1984, a empresa passou a operar nova unidade, em Periquitos, no Município de Ponta Grossa e em outubro de 1989, uma nova unidade entrou em funcionamento em Irati, junto a BR 277.
                    </p>
                    <p class="text-gray-600 font-Montserrat text-base mt-5">
                      Hoje, passados praticamente seis décadas do início desta história e após tantas peripécias para adquirir a primeira área de 72 alqueires de terreno pedregoso, tanto trabalho, tanta persistência, sacrifícios, tempos difíceis, muitas alegrias, progresso e sonho realizado em família, continuamos sendo uma empresa familiar, sólida no mercado de agregados, oferecendo mais de 200 postos de trabalho em suas unidades e contribuindo para o desenvolvimento do país.
                    </p>
                    <p class="text-gray-600 font-Montserrat text-base mt-5">
                      Agostinho Boscardin ao longo de sua história, apoiou as iniciativas de interesse comunitário, social, educacional e cultural. Como uma árvore que produz bons frutos, também os seus familiares, alicerçados nos exemplos do seu patriarca, continuam produzindo os frutos plantados.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- Interessado !-->
    <div id="interessado" class="py-12 md:py-28 bg-layout_branco bg-cover bg-no-repeat bg-cente">
      <div class="mx-5 md:mx-12 2xl:mx-96">
        <div class="grid grid-cols-12 gap-6">
          <div class="col-span-12 md:col-span-8">
            <h2
                class="text-gray-900 font-Montserrat text-2xl md:text-5xl"
              >
                Interessado nos nossos serviços? Entre em contato com a gente.
            </h2>
            <button
              @click="$modal.show('modal_zap')"
              class="border-none bg-secondary text-base font-semibold text-white py-6 font-Montserrat mt-4 w-full hover:bg-primary md:w-60 mt-10"
            >
              Entrar em contato
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
